import type { InferGetServerSidePropsType, GetServerSideProps } from 'next';
import LocationSelectorDrawer from '@/components/location-selector';
const envConfig = ENV;




export const getStaticProps: GetServerSideProps<{
	res: any;
}> = async () => {
		const res = await fetch(
			`${envConfig.CIA_HOST}/api/v1/location/serviceable-cities`,
			{
				method: 'GET',
			}
		);

		if (!res.ok) {
			console.log(res)
			throw new Error('Failed To Fetch');
		}
		return { props: { res: await res.json() },revalidate:43200 };


};

function CityClient({ res }: any) {
	return <LocationSelectorDrawer {...res} />;
}

CityClient.avoidBaseLayout = true;

export default CityClient;
